import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Policy from '../views/Policy.vue'
import Product from "@/views/Product.vue";
import product1 from "@/views/product/product1.vue";
import product2 from "@/views/product/product2.vue";
import product3 from "@/views/product/product3.vue";
import product4 from "@/views/product/product4.vue";
import compont from "@/views/compont.vue";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/product/product1',
    name: 'product1',
    component: product1
  },
  {
    path: '/product/product2',
    name: 'product2',
    component: product2
  },
  {
    path: '/product/product3',
    name: 'product3',
    component: product3
  },
  {
    path: '/product/product4',
    name: 'product4',
    component: product4
  },
  {
    path: '/compont',
    name: 'compont',
    component: compont
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
