<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css"
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";
export default {
  name: 'HomeView',
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      swiperIns: null
    }
  },
  mounted() {
    this.initCa();
  },
  methods: {
    /** 初始化走马灯 */
    initCa() {
      this.swiperIns = new Swiper('.swiper-container-2', {
        autoplay: true,
        loop: true,
        navigation: {
          nextEl: '.l_btn',
          prevEl: '.r_btn',
        },
      })
    },
    /** 跳转 */
    pathTo(url) {
      this.$router.push({
        path: url
      })
    },
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container index">
      <div class="swiper-container-2">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="cover" @click="pathToProduct('/product', 4)">
              <img class="cover-img" src="@/assets/images/bg-1.jpg" alt>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="cover" @click="pathToProduct('/product', 3)">
              <img class="cover-img" src="@/assets/images/bg-2.jpg" alt>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="cover" @click="pathToProduct('/product', 2)">
              <img class="cover-img" src="@/assets/images/bg-3.jpg" alt>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="cover" @click="pathToProduct('/product', 1)">
              <img class="cover-img" src="@/assets/images/bg-4.jpg" alt>
            </div>
          </div>
        </div>
        <div class="l_btn lrbtn">
          <img src="@/assets/static/picture/index_left_w.png">
        </div>
        <div class="r_btn lrbtn">
          <img src="@/assets/static/picture/index_right_g.png">
        </div>
      </div>
      <div class="contentbox">
        <div class="title">
          <img src="@/assets/static/picture/index_content_0.jpg">
        </div>
        <ul class="cont clearfix">
          <!-- product-1 -->
          <li class="item_0">
            <img class="item_img" src="@/assets/images/pd-1.png">
            <dl>
              <dt>豆乎氨基酸净澈洁面乳</dt>
              <dd>温和无刺激<br>敏感肌适用</dd>
            </dl>
            <a @click="pathToProduct('/product', 1)"
               target="_blank" alt="首页户外长效控油抗痘套装">
              <img src="@/assets/static/picture/index_content_buybtn.png">
            </a>
          </li>
          <!-- product-2 -->
          <li class="item_1">
            <img class="item_img" src="@/assets/images/pd-2.png">
            <dl>
              <dt>豆乎萃活康肤水</dt>
              <dd>毛孔调理，打好肌底<br>去油+控油，一步到位</dd>
            </dl>
            <a @click="pathToProduct('/product', 2)"
               target="_blank" alt="首页热销抗痘星品痘贴">
              <img src="@/assets/static/picture/index_content_buybtn.png">
            </a>
          </li>
          <!-- product-3 -->
          <li class="item_2">
            <img class="item_img" src="@/assets/images/pd-3.png">
            <dl>
              <dt>豆乎植合舒安乳</dt>
              <dd>缓和肌肤应激反应<br>辅助吸收，敏感肌适用</dd>
            </dl>
            <a @click="pathToProduct('/product', 3)"
               target="_blank" alt="首页日夜双效抗痘">
              <img src="@/assets/static/picture/index_content_buybtn.png">
            </a>
          </li>
          <!-- product-4 -->
          <li class="item_3">
            <img class="item_img" src="@/assets/images/pd-4.png">
            <dl>
              <dt>豆乎植合维肤霜</dt>
              <dd>抑菌消炎，软化角质<br>促进肌肤再生，不留痘坑</dd>
            </dl>
            <a @click="pathToProduct('/product', 4)"
               target="_blank" alt="首页乐肤洁抗痘CP">
              <img src="@/assets/static/picture/index_content_buybtn.png">
            </a>
          </li>
        </ul>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";
a {
  cursor: pointer;
}

.item_img {
  height: 210px;
}

.swiper-container-2 {
  width: 100%;
  height: 596px;
  overflow: hidden;

  .cover {
    display: flex;
    align-items: center;
    width: 1920px;
    height: 596px;
    cursor: pointer;

    .cover-left {
      width: 50%;
      height: 596px;
    }
    .cover-right {
      width: 50%;
      height: 596px;
    }
  }
}
</style>
