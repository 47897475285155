var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('home-header'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"product clearfix"},[_c('div',{staticClass:"product_introduction clearfix"},[_vm._m(0),_vm._m(1),_c('h3',{staticStyle:{"width":"138px","text-align":"center","float":"right","font-size":"14px","padding":"15px 0","color":"#21bdbd","font-weight":"bold"}},[_vm._v(" 搭配使用更有效")]),_c('ul',{staticClass:"related_products"},[_c('li',[_vm._m(2),_c('br'),_vm._v(" 豆乎氨基酸净澈洁面乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 1)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(3),_c('br'),_vm._v(" 豆乎萃活康肤水 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 2)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(4),_c('br'),_vm._v(" 豆乎植合舒安乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 3)}}},[_vm._v("查看")])])])])]),_vm._m(5)])]),_c('home-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/pd-4-m.png"),"alt":"乐肤洁控油清爽洁面乳"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("豆乎植合维肤霜 ")]),_c('div',{staticClass:"title_e"},[_vm._v("ZHIHE WEIFU CREAM")]),_c('div',{staticClass:"content_txt"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"txt_0"},[_vm._v("减轻痘痘症状，抑菌防扩散")]),_c('div',{staticClass:"txt_1"},[_c('dd',[_vm._v("通过天然保湿因子的作用软化角质，配以温和脱落角质的成分，渗透软化坚硬的表皮组织，打开毛孔帮助疏通堵塞的毛囊导管，为抗炎因子渗透入病灶开辟快速通道。 ")]),_c('dd',[_vm._v("多种天然而强效的抗炎成分抑制炎性介质的分泌，促进具有拮抗炎性介质作用的物质生成。 ")]),_c('dd',[_vm._v("通过天然抑菌成分可从根本抑制细菌的增殖分化，可有效抑制痤疮丙酸杆菌、表皮葡萄球菌等增殖，无副作用同时达到抑菌功能。 ")]),_c('dd',[_vm._v("促进伤口愈合，预防疤痕产生，及时修复受损肌肤，促进细胞生长。 ")]),_c('dd',[_vm._v("多重保湿，预防毛囊导管堵塞，改善干燥，恢复毛囊导管的正常运输功能。 ")]),_c('dd',[_vm._v("调节油脂，预防脂质改变加重炎症，从源头减轻毛囊导管运输压力，保持通畅。")])]),_c('div',{staticClass:"txt_2 clearfix"},[_c('b',[_vm._v("使用方法： ")]),_c('span',[_vm._v("取适量置于指腹，点涂于痘痘部位，按摩至吸收，一天使用5-6次，随时护理。")])]),_c('div',{staticClass:"txt_3 clearfix"},[_c('div',{staticStyle:{"HEIGHT":"50px","CLEAR":"both"}},[_c('span',[_vm._v("￥ 139")]),_c('span',{staticClass:"s"},[_vm._v("/30g ")]),_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.8.5aa4410cxaGrJN&id=828824544734&skuId=5725696906455","target":"_blank"}},[_vm._v("立即购买")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-1-a.png"),"alt":"乐肤洁抗痘护理爽肤液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-2-a.png"),"alt":"乐肤洁清爽控油乳液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-3-a.png"),"alt":"乐肤洁隐形净痘贴"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-image-box"},[_c('img',{staticClass:"container-image",attrs:{"src":require("@/assets/images/pd-4-d.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }