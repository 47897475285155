var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('home-header'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"product clearfix"},[_c('div',{staticClass:"product_introduction clearfix"},[_vm._m(0),_vm._m(1),_c('h3',{staticStyle:{"width":"138px","text-align":"center","float":"right","font-size":"14px","padding":"15px 0","color":"#21bdbd","font-weight":"bold"}},[_vm._v(" 搭配使用更有效")]),_c('ul',{staticClass:"related_products"},[_c('li',[_vm._m(2),_c('br'),_vm._v(" 豆乎氨基酸净澈洁面乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 1)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(3),_c('br'),_vm._v(" 豆乎植合舒安乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 3)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(4),_c('br'),_vm._v(" 豆乎植合维肤霜 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 4)}}},[_vm._v("查看")])])])])]),_vm._m(5)])]),_c('home-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/pd-2-m.png"),"alt":"乐肤洁控油清爽洁面乳"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("豆乎萃活康肤水 ")]),_c('div',{staticClass:"title_e"},[_vm._v("CUIHUO KANGFU LOTION")]),_c('div',{staticClass:"content_txt"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"txt_0"},[_vm._v("10+种保湿成分|强化角质层")]),_c('div',{staticClass:"txt_1"},[_c('dd',[_vm._v("补充10+种皮肤基质类型或天然结构氨基酸的保湿成分，瞬时抓水锁水，强化角质层维护屏障功能。 ")]),_c('dd',[_vm._v("积雪草提取物含有积雪草酸和羟基积雪草酸，产生提高和修复肌肤自身屏障功能，防止和纠正肌肤免疫功能紊乱。 ")]),_c('dd',[_vm._v("专利抗炎舒缓成分，发挥镇定舒缓、抗炎抑菌的作用，加强保护功能 ")])]),_c('div',{staticClass:"txt_2 clearfix"},[_c('b',[_vm._v("使用方法： ")]),_c('span',[_vm._v("日常使用时，洁面后，取5-6滴至化妆棉或掌心内，轻拍至脸上吸收；干燥或敏感不适时，取适量康肤水湿润化妆棉，敷于不适肌肤部位上1-2分钟，有效舒缓不适感。")])]),_c('div',{staticClass:"txt_3 clearfix"},[_c('div',{staticStyle:{"HEIGHT":"50px","CLEAR":"both"}},[_c('span',[_vm._v("￥ 88")]),_c('span',{staticClass:"s"},[_vm._v("/80ml ")]),_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.5.5aa4410cxaGrJN&id=828667737402&skuId=5727645451068","target":"_blank"}},[_vm._v("立即购买")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-1-a.png"),"alt":"乐肤洁抗痘护理爽肤液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-3-a.png"),"alt":"乐肤洁清爽控油乳液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-4-a.png"),"alt":"乐肤洁隐形净痘贴"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-image-box"},[_c('img',{staticClass:"container-image",attrs:{"src":require("@/assets/images/pd-2-d.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }