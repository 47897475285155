<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "product2",
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      pid: 1,
      productList: [
        {
          // pid=1

        }
      ]
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
  },
  methods: {
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container">
      <div class="product clearfix">
        <div class="product_introduction clearfix">
          <div class="img">
            <img src="@/assets/images/pd-2-m.png" alt="乐肤洁控油清爽洁面乳">
          </div>
          <div class="content">
            <div class="title">豆乎萃活康肤水&nbsp;</div>
            <div class="title_e">CUIHUO KANGFU LOTION</div>
            <!--%if(!pr.getTag().equals("")){%> <div class="txt_0"><span>%=pr.getTag()%></span></div>%}%>-->
            <div class="content_txt">
              <div class="introduce">
                <div class="txt_0">10+种保湿成分|强化角质层</div>
                <div class="txt_1">
                  <dd>补充10+种皮肤基质类型或天然结构氨基酸的保湿成分，瞬时抓水锁水，强化角质层维护屏障功能。
                    <dd>积雪草提取物含有积雪草酸和羟基积雪草酸，产生提高和修复肌肤自身屏障功能，防止和纠正肌肤免疫功能紊乱。
                      <dd>专利抗炎舒缓成分，发挥镇定舒缓、抗炎抑菌的作用，加强保护功能 </dd>
                </div>
                <div class="txt_2 clearfix"><b>使用方法：&nbsp;</b><span>日常使用时，洁面后，取5-6滴至化妆棉或掌心内，轻拍至脸上吸收；干燥或敏感不适时，取适量康肤水湿润化妆棉，敷于不适肌肤部位上1-2分钟，有效舒缓不适感。</span>
                </div>
                <div class="txt_3 clearfix">
                  <div style="HEIGHT: 50px; CLEAR: both"><span>￥ 88</span><span class="s">/80ml
										</span><a style="cursor: pointer" href="https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.5.5aa4410cxaGrJN&id=828667737402&skuId=5727645451068"
                              target="_blank">立即购买</a></div>
                </div>
              </div>
            </div>
          </div>
          <h3
              style=" width:138px; text-align:center; float:right; font-size: 14px; padding:15px 0; color: #21bdbd; font-weight: bold;">
            搭配使用更有效</h3>
          <ul class="related_products">
            <li>
              <a><img src="@/assets/images/pd-1-a.png"
                      alt="乐肤洁抗痘护理爽肤液"></a>
              <br>
              豆乎氨基酸净澈洁面乳
              <span class="li_a"><a @click="pathToProduct('none', 1)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-3-a.png"
                      alt="乐肤洁清爽控油乳液"></a>
              <br>
              豆乎植合舒安乳
              <span class="li_a"><a @click="pathToProduct('none', 3)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-4-a.png"
                      alt="乐肤洁隐形净痘贴"></a>
              <br>
              豆乎植合维肤霜
              <span class="li_a"><a @click="pathToProduct('none', 4)" style="cursor: pointer">查看</a></span>
            </li>
          </ul>
        </div>
        <div class="container-image-box">
          <img class="container-image" src="@/assets/images/pd-2-d.jpg" alt>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";

.container-image-box {
  width: 100%;
  display: flex;
  justify-content: center;

  .container-image {
    width: 650px;
    margin: 50px auto;
  }
}
</style>
