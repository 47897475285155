<script>
export default {
  name: "homeFooter",
  data() {
    return {}
  },
  methods: {
    /** 跳转 */
    pathTo(url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<template>
  <div class="page">
    <div class="footer">
      <div class="content">
        © 豆乎版权所有 <a @click="pathTo('/policy')">隐私政策</a>&nbsp;&nbsp;<a style="color:#999;"
                                                                                @click="pathTo('/about')">关于豆乎</a>
        &nbsp;&nbsp; <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2024168453号-6</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";
@import "@/assets/static/css/idangerous.swiper.css";

a {
  cursor: pointer;
}
</style>
