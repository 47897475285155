<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "Policy",
  components: {HomeFooter, HomeHeader}
}
</script>

<template>
  <div class="page" style="padding-top: 100px">
    <home-header></home-header>
    <div class="bg_0">
      <h1>
        隐私政策
      </h1>
    </div>
    <div class="txt-cont">
      豆乎品牌归属于阿茉尔（广州）生物科技有限公司(统称“豆乎”或“我们”)，　我们十分尊重消费者对隐私的关心，并致力于为消费者提供完善的隐私保护。本隐私政策将帮助中国大陆地区的消费者了解我们收集的个人信息种类、个人信息使用方式、处理目的，以及我们采取的个人信息保护措施。
    </div>
    <div class="txt-cont">
      请您细致阅读本隐私政策，特别是以<span>粗体</span>标识的条款，以了解豆乎如何收集、使用、存储和传输您的个人信息，您如何行使个人信息主体的权利，以及豆乎对未成年人的个人信息保护等。如对本隐私政策内容有任何疑问、意见或建议，您可通过“联系我们”中提供的联系方式与我们取得联系。
    </div>

    <div class="txt-contlist bold">本隐私政策旨在帮助您更好地理解以下主题，您可以根据以下索引阅读相应章节：</div>
    <div class="txt-contlist bold">一、我们如何收集和使用个人信息？</div>
    <div class="txt-contlist bold">二、我们如何使用COOKIES或同类技术</div>
    <div class="txt-contlist bold">三、我们如何共享、转让、披露您的个人信息？</div>
    <div class="txt-contlist bold">四、我们如何存储和保护您的个人信息？</div>
    <div class="txt-contlist bold">五、您如何行使个人信息主体的权利？</div>
    <div class="txt-contlist bold">六、我们如何保护未成年人的个人信息？</div>
    <div class="txt-contlist bold">七、隐私政策的更新</div>
    <div class="txt-contlist bold">八、联系我们</div>

    <div class="txt-cont bold">
      一、我们如何收集和使用个人信息？
    </div>
    <div class="txt-cont">
      豆乎关注消费者的体验，在与您的互动过程中，我们可能从多个来源获得您的个人信息，并使用您的个人信息以为您和其他消费者提供更为优质的服务。
    </div>
    <div class="txt-cont bold">
      （一）我们将通过以下途径收集和获得您的个人信息：
    </div>
    <div class="txt-cont">
      1．您向我们提供的个人信息。例如您注册会员或购买商品时，向我们提供的个人信息。
    </div>
    <div class="txt-cont">
      2．第三方共享的您的个人信息。例如您在第三方平台购买豆乎产品，第三方向我们共享的您的个人信息。
    </div>
    <div class="txt-cont">
      3．我们在与您互动过程中获取的您的个人信息。例如您在访问豆乎网站或微信小程序时，我们汇总、记录的信息。
    </div>
    <div class="txt-cont bold">
      （二）我们会出于以下目的，收集和使用您以下类型的个人信息：
    </div>
    <div class="txt-cont bold">
      1．会员注册及登录
    </div>
    <div class="txt-cont">
      当您通过各类线上线下渠道选择注册成为我们的会员时，您会向我们提供您的信息，包括您的姓名、手机号、电子邮箱，我们根据您提供的信息为您创建会员账号。您还可以向我们提供您的生日、性别、地区、头像和昵称，以便于我们向您提供更加个性化的会员服务。当您在注册会员后购买豆乎产品，我们将收集您的订单信息，包括订单号、订单金额、订单商品，并根据订单情况为您计算会员积分。如果您仅需使用浏览、购物等功能，您不需要注册成为豆乎会员及提供上述信息。
    </div>
    <div class="txt-cont bold">
      需要向您说明的是，为了实现会员账号的一体化管理，我们将基于您的手机号，将您在不同平台（如淘宝、微信小程序）及不同平台的各个店铺（如天猫旗舰店、男士天猫旗舰店）的会员信息进行同步，例如，如果您在不同平台/不同店铺分别购买了豆乎产品，您的会员积分可能进行累积计算。
    </div>
    <div class="txt-cont bold">
      2．在线购物
    </div>
    <div class="txt-cont">
      当您通过我们的自营渠道购买我们的商品时，为了向您提供交付服务，您需要向我们提供收货人姓名、收货地址、邮政编码、手机号、支付状态信息。<span>如果您拒绝提供此类信息，我们将无法完成相关交付服务。</span>如您为他人订购商品，您需要提供该实际订购人的前述信息。如果您需要开具发票，您还需要向我们提供您的发票抬头、纳税人识别号以及您的电子邮箱。
    </div>
    <div class="txt-cont bold">
      3．客户服务
    </div>
    <div class="txt-cont">
      当您联系我们的客服或使用其他用户响应功能时，我们可能需要您提供必要的个人信息以匹配您的身份，如您的会员账号、手机号、电子邮箱、所在省份及城市。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
    </div>
    <div class="txt-cont bold">
      4．商品或服务推送
    </div>
    <div class="txt-cont">
      如您同意我们向您推送商品或服务，或当您选择注册为我们的会员时，我们可能通过多种渠道向您推送商品或服务相关的营销资讯，<span>推送的渠道包括但不限于短信、电子邮件、第三方平台、电话、邮寄等。</span>
    </div>
    <div class="txt-cont bold">
      同时，基于您向我们提供的信息（如会员信息）及我们可能收集的信息（如订单信息），我们可能会基于上述一项或几项信息的结合，进行用户行为分析及用户画像制作，用于提取产品使用情况、位置信息等相关特征，以便向您推送更契合您需求的商品或服务。我们将尽可能消除身份指向性，使用间接用户画像以推送个性化广告或帮助我们改善服务。
    </div>
    <div class="txt-cont bold">
      我们也可能通过第三方平台展示定向广告。我们可能将您手机号、电子邮箱或其他信息脱敏处理后转换成唯一值，第三方平台可使用该唯一值与其平台用户或其向您收集的其他数据进行匹配，以便在其平台展示基于用户兴趣的广告。如要退订上述广告功能，您需要调整您在第三方平台上的个性化广告设置，我们建议您关注并详细阅读第三方平台的隐私政策。
    </div>
    <div class="txt-cont bold">
      同时，需要向您说明的是，除了您最先接触的豆乎品牌外，我们可能邀请您同步订阅阿茉尔旗下丰富多元的品牌，向您提供全方位的豆乎品牌资讯。
    </div>
    <div class="txt-cont">
      如您不希望我们将您的个人信息用于直接营销，您可以随时按照“您如何行使个人信息主体的权利？”部分的说明，退订我们的商品或服务推送。我们会按照您的要求停止将您的个人信息用于营销推送。
    </div>
    <div class="txt-cont bold">
      5．其他情形
    </div>
    <div class="txt-cont">
      基于您的同意或存在其他合法性基础时，我们可能在其他情况下收集并使用您的个人信息。例如，如果您预约参加豆乎的特别活动，您可能需要向我们提供您的姓名、手机号码、电子邮箱、会员账号、所在省份及城市，以便于我们为您妥善安排相关活动。在其他情形下，您向我们提供的个人信息可能包括：
    </div>
    <div class="txt-contlist">
      • 会员信息，如会员账号、密码、会员积分
    </div>
    <div class="txt-contlist">
      • 联系方式，如姓名、手机号、收件地址、电子邮箱
    </div>
    <div class="txt-contlist">
      • 年龄和出生日期
    </div>
    <div class="txt-contlist">
      • 性别
    </div>
    <div class="txt-contlist">
      • 所在省份和城市
    </div>
    <div class="txt-contlist bold">
      • 付款信息，如银行卡号、有效日期、账单地址
    </div>
    <div class="txt-contlist bold">
      • 购买记录，如购买渠道、订单号、购买时间、购买价格
    </div>
    <div class="txt-contlist">
      • 第三方平台信息，如平台账号、昵称、头像
    </div>
    <div class="txt-contlist">
      • 产品或服务偏好
    </div>
    <div class="txt-contlist bold">
      • 您的皮肤状况，如皮肤类型、日常护理方式、皮肤问题，此类信息可能被视为敏感个人信息
    </div>
    <div class="txt-contlist">
      • 您提供的图片、视频、音频、评论
    </div>
    <div class="txt-cont bold">
      “敏感个人信息”是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括个人财产信息（如您的交易和消费记录）、生物识别信息（如您的基因、指纹和面部识别信息）、个人身份信息（如您的身份证号）、个人健康信息（如过敏情况、皮肤类型、是否怀孕）等。我们仅会在您明确同意我们进行处理的情况下收集和使用您的敏感个人信息。在同意我们处理您的敏感个人信息之前，请您予以慎重考虑。
    </div>
    <div class="txt-cont bold">
      二、我们如何使用COOKIES或同类技术
    </div>
    <div class="txt-cont">
      当您浏览豆乎网站、点击我们的相关广告（包括豆乎在第三方平台投放的广告）时，我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的信息。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。通过使用COOKIES或同类技术，用户可以获得简单易行并富个性化的网络体验，使我们或第三方合作伙伴能更好、更快地为您服务，并且使您获得的服务经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被您的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。
    </div>

    <div class="txt-cont bold">
      三、我们如何共享、转让、披露您的个人信息？
    </div>

    <div class="txt-cont">
      我们会对您的个人信息负责，不会出售您的个人信息。在以下情况及您同意或法律允许的情形下，我们才会共享、转让或披露您的个人信息。
    </div>

    <div class="txt-cont bold">
      （一）我们如何与他人共享您的个人信息？
    </div>
    <div class="txt-cont bold">
      1.与关联方的必要共享
    </div>
    <div class="txt-cont">
      为了向您提供豆乎中国大陆地区全品牌体系的一体化服务及统一的会员管理，如更准确的个性化推荐、更安全的会员账号保障、更优质的客服服务，您的个人信息可能会在我们和我们的关联方之间进行必要共享。
    </div>

    <div class="txt-cont bold">
      关联方对您个人信息的处理也需遵守本隐私政策。如果任何关联方希望以本隐私政策未规定且法律法规未允许的方式使用您的个人信息，该等关联方将事先通知您并征得您的同意。
    </div>

    <div class="txt-cont bold">
      2.为向您交付商品或提供服务
    </div>

    <div class="txt-cont">
      为保证您需求的商品或服务能够顺利提供，我们可能向合作伙伴及其他第三方共享您的信息。该等合作伙伴及其他第三方例如：提供支付服务的支付机构、提供物流服务的第三方物流公司。
    </div>

    <div class="txt-cont">
      我们仅会出于合法、正当、必要、特定、明确的目的向合作伙伴或其他第三方共享您的个人信息。<span>对我们与之共享个人信息的公司、组织和个人，我们会与其签署合作协议，明确合作伙伴或其他第三方的个人信息保护义务。</span>
    </div>

    <div class="txt-cont bold">
      3.委托受信任的第三方处理您的个人信息
    </div>

    <div class="txt-cont">
      为协助我们处理您的个人信息，我们可能会委托值得信赖的第三方代表我们进行个人信息处理。该等受委托第三方例如：协助我们提供信息技术服务的第三方，包括我们的数据库、软件和应用程序的平台提供商、托管服务商、开发服务商、运维服务商等。
    </div>

    <div class="txt-cont">
      我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的向该等受委托第三方共享您的个人信息。<span>对于受委托的第三方，他们仅能接触到其履行职责所必需的信息，并且我们会与其签署委托处理协议，明确要求受委托第三方不能将您的个人信息用于任何其他目的。</span>我们会尽最大努力监督受委托第三方会保护您的个人信息安全。
    </div>
    <div class="txt-cont bold">
      （二）我们如何转让您的个人信息？
    </div>
    <div class="txt-cont">
      1.随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。<span>我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</span>
    </div>
    <div class="txt-cont">
      2.在获得您的明确同意后，我们会向第三方转让您的个人信息。
    </div>
    <div class="txt-cont bold">
      （三）我们披露您的个人信息的其他方式
    </div>
    <div class="txt-cont">
      除上述情况外，我们可能在以下情况下共享、转让或公开披露您的个人信息：
    </div>
    <div class="txt-cont">
      1.如果我们获得您的同意；
    </div>
    <div class="txt-cont bold">
      2.以下无需事先征得您的同意的情形：
    </div>
    <div class="txt-contlist bold">
      （1） 与国家安全、国防安全直接相关的；
    </div>
    <div class="txt-contlist bold">
      （2） 与公共安全、公共卫生、重大公共利益直接相关的；
    </div>
    <div class="txt-contlist bold">
      （3） 与刑事侦查、起诉、审判和判决执行等司法或行政执法直接相关的；
    </div>
    <div class="txt-contlist bold">
      （4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </div>
    <div class="txt-contlist bold">
      （5） 您自行向社会公众公开的信息；
    </div>
    <div class="txt-contlist bold">
      （6） 从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="txt-contlist bold">
      （7） 与我们履行法律法规规定的义务相关的。
    </div>
    <div class="txt-cont bold">
      根据法律规定，共享、转让、披露经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </div>
    <div class="txt-cont bold">
      四、我们如何存储和保护您的个人信息？
    </div>
    <div class="txt-cont bold">
      （一）我们如何存储您的个人信息？
    </div>
    <div class="txt-cont">
      我们将收集到的您的个人信息存储在中国境内的服务器上。因业务需要，我们也可能向境外提供您的个人信息。<span>如果发生出境的情形，我们将充分告知您相关的情况且取得您的单独同意，并履行法律法规关于个人信息出境的其他要求。</span>
    </div>
    <div class="txt-cont">
      一般而言，我们仅在为实现目的所必需的时间及法律、行政法规所规定的保存期限内保留您的个人信息。例如：
    </div>
    <div class="txt-cont">
      会员信息：如果您注册成为会员，若您需要使用会员服务，我们需要一直保存您的会员信息（如注册手机号码、会员账号），以保证您正常使用会员服务。当您注销会员账号后，我们将依法删除相应的信息；
    </div>
    <div class="txt-cont">
      订单信息：如果您在我们的自营渠道线上购物，我们会保存您在线购物相关的数据，以便我们向您交付商品及提供后续服务，同时，我们会将该个人信息保留一定时间以便我们可用于回应任何投诉、询问或者处理与该购物相关的问题。
    </div>
    <div class="txt-cont bold">
      （二）我们如何保护您的个人信息？
    </div>
    <div class="txt-cont">
      我们非常重视您的个人信息安全。我们会尽一切努力保护您的个人信息免遭滥用、干扰、丢失、未经授权的访问、修改或披露。我们的措施包括实施适当的访问控制，投入最新的信息安全功能以保护我们使用的IT环境，并确保我们尽可能对个人信息进行加密技术、去标识化和匿名化处理。我们仅允许我们的员工和代理，仅在确有必要知情的情况下，才能访问您的个人信息，并且在第三方处理时需要遵守严格的合同义务。若发生个人信息泄露等安全事件，我们会启动应急预案及补救措施，阻止安全事件扩大，并依法履行相关的报告及通知义务。
    </div>
    <div class="txt-cont bold">
      需要提示您注意的是，如果您通过第三方平台购买我们的产品或接受我们的服务，请您注意，这些网站/应用程序有自己的隐私政策，我们不对第三方隐私政策负责或承担责任。在您向这些网站/应用程序提交任何个人信息之前，请您审阅相应政策。我们可能会为您提供通过社交媒体登录的路径。请您注意，通过社交媒体登录时，您可能会与我们共享您的一些社交账号信息，具体取决于社交媒体平台的设置。请您访问相关的社交媒体平台，并查看其隐私政策，以了解您的个人信息在该等场景下是如何被共享和使用的。
    </div>
    <div class="txt-cont bold">
      五、您如何行使个人信息主体的权利？
    </div>
    <div class="txt-cont">
      您依法享有作为个人信息主体的权利，您可以随时行使这些权利。我们在下文提供了您的权利概述以及您需要注意的事项。<span>您需注意的是，为了处理您的权利请求，我们可能会要求您提供身份证明。</span>
    </div>
    <div class="txt-cont">
      <span>1.知情权。</span>您有权获得有关我们如何使用您的个人信息以及您拥有哪些权利的清楚、透明且易于理解的信息。因此，我们现通过本隐私政策向您提供此等信息。
    </div>
    <div class="txt-cont">
      <span>2.查阅和复制权。</span>您有权查阅、复制我们持有的关于您的个人信息，<span>但可能有相应的限制条件，如需要查明确认您的身份。</span>请您通过“联系我们”中提供的联系方式来行使这一权利。如果您有会员账号，您也可以通过具体会员渠道的账号管理功能查阅您的个人信息。
    </div>
    <div class="txt-cont">
      <span>3.访问更正权。</span>您有权访问、更正或更新您的个人信息，<span>但可能有相应的限制条件，如需要查明确认您的身份。</span>请您通过“联系我们”中提供的联系方式来行使这一权利。如果您有会员账号，您也可以通过具体会员渠道的账号管理功能更正您的个人信息。
    </div>
    <div class="txt-cont">
      <span>4.删除权。</span>在下列情形中，您有权要求我们删除您的个人信息：（1）如果处理的目的已经实现，或无法实现，或不再需要个人信息以达成此目的；（2）我们不再向您提供产品或服务；（3）我们没有合法基础收集您的个人信息的，或您撤回同意时；（4）如果我们对您的个人信息处理活动违反法律、行政法规或我们之间的约定；（5）法律、行政法规规定的其他情形。<span>请注意，如果您要求删除的个人信息属于提供某些服务所必需的信息，我们可能无法继续向您提供这些服务，但是这不会影响我们提供与这些个人信息无关的其他服务。同时，如果法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。</span>请您通过“联系我们”中提供的联系方式来行使这一权利。
    </div>
    <div class="txt-cont">
      <span>5.拒绝权。</span>对于我们发出的电邮、短信等营销资讯，您可点击电子邮件底部的“取消订阅”链接，或回复短信退订信息，或表示拒绝接受营销电话等方式，以选择不接收我们的营销推送。或者您可以通过“联系我们”中提供的联系方式来行使这一权利。
    </div>
    <div class="txt-cont">
      <span>6.转移权。</span>您有权要求我们将您的个人信息转移至您指定的个人信息处理者（需符合国家网信部门规定的特定条件）。请您通过“联系我们”中提供的联系方式来行使这一权利。
    </div>
    <div class="txt-cont">
      <span>7.撤销同意。</span>如果我们是基于您的同意这一合法性基础而处理您的个人信息，您有权随时撤销您的同意。请您理解，<span>特定的业务功能和服务将需要您的个人信息才能完成，当您撤回同意后，我们无法继续为您提供撤回同意所对应的服务，也将不再处理您相应的个人信息。</span>请您通过“联系我们”中提供的联系方式来行使这一权利。
    </div>
    <div class="txt-cont">
      <span>8．注销账号。</span>在注册会员账号之后，您可以选择注销您的账号。需要向您说明的是，<span>如您在第三方平台注册会员，账号注销有赖于该第三方平台的功能设计，我们建议您查阅账号注册平台的隐私政策以了解相关流程。</span>如果您无法通过第三方平台注销会员，您可以通过“联系我们”中提供的联系方式来向我们反馈。
    </div>
    <div class="txt-cont">
      <span>六、我们如何保护未成年人的个人信息？</span>
    </div>
    <div class="txt-cont bold">
      如您为未成年人，我们建议您请您的父母或监护人仔细阅读本隐私政策。在征得他们同意的前提下，您可使用我们的服务或向我们提供信息。如果没有父母或监护人的同意，未成年人不得创建自己的会员账号。
    </div>
    <div class="txt-cont">
      对于经父母或监护人同意使用我们的服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。若我们无意间收集到未成年人的个人信息，在您发现并通知我们后，我们会删除该信息。
    </div>
    <div class="txt-cont bold">
      七、隐私政策的更新
    </div>
    <div class="txt-cont bold">
      本隐私政策可能不经通知而定期更新，以反映个人信息保护政策的变更。我们会在豆乎网站发布信息通知隐私政策的任何重要变更，并在隐私政策最上方标明最近更新的日期。如果我们对本隐私政策作出重大变更，我们将以更为显著的方式（包括但不限于浏览页面特别提示等）说明本隐私政策的重大变更内容。无论我们是否已经向您发送有关变更的具体通知，如您继续使用我们提供的服务，更新后的隐私政策将同时对您和我们产生约束。请您定期查看网站，或按照下文“联系我们”部分所列联络方式与我们取得联系，以了解隐私政策的更新情况。
    </div>
    <div class="txt-cont bold">
      八、联系我们
    </div>
    <div class="txt-cont">
      我们设立了个人信息保护专职人员，如果您对我们如何处理、保护您的个人信息有任何疑问或担忧，或者您想行使上述任何权利，<span>请与豆乎的个人信息保护工作人员联系，可发送电子邮件至【service@aromore.com】。</span>
    </div>
    <div class="txt-cont">
      通常情况下，我们将在收到您的问题、意见或建议，并验证您的身份后的15个工作日内予以回复；<span>如果情况复杂、需要延期的，我们也会在15个工作日内向您说明。</span>如您不满意我们的回复，还可以向消费者权益保护部门投诉或向有管辖权的法院提起诉讼。
      <br><br><br>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";

.bg_0 {
  padding-top: 56px;
  padding-bottom: 20px;
  border-bottom: 1px #d1d1d1 solid;
}

h1 {
  height: 39px;
  border-left: 6px #005bac solid;
  line-height: 39px;
  font-size: 24px;
  color: #1a1921;
  width: 936px;
  margin: 0 auto;
  padding-left: 15px;
  font-family: "微软雅黑" "microsoft yahei";
}

.title {
  width: 950px;
  margin: 20px auto 0 auto;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
}

.txt-cont {
  width: 950px;
  margin: 0 auto;
  text-indent: 2em;
  padding-top: 22px;
  font-size: 14px;
  line-height: 25px;
  /*font-weight: bold;*/
}

.txt-cont span {
  font-weight: bold;
}

.txt-cont.bold {
  font-weight: bold;
}

.txt-contlist {
  width: 950px;
  margin: 0 auto;
  text-indent: 2em;
  padding-top: 22px;
  font-size: 14px;
  line-height: auto;
  /*font-weight: bold;*/
}

.txt-contlist.bold {
  font-weight: bold;
}

.txt-cont a {
  font-size: 14px;
  color: #005bac;
  line-height: 20px;
}

.header {
  width: 950px;
  margin: 0 auto;
  text-indent: 2em;
  padding-top: 22px;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  margin-top: 29px;
  line-height: 20px;
  padding-bottom: 37px;
}

.foot {
  width: 950px;
  margin: 0 auto;
  text-indent: 2em;
  padding-top: 22px;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  margin-top: 29px;
  line-height: 20px;
  padding-bottom: 37px;
}

.pic {
  background: #e3f6fd;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 127px;
}

@media (max-width: 1000px) {
  .bg_0 {
    padding-top: 1.45rem;
  }

  h1 {
    width: 95%;
  }

  .header {
    width: 95%;
  }

  .title {
    width: 95%;
  }

  .txt-cont {
    width: 95%;
  }

  .txt-contlist {
    width: 95%;
    line-height: 20px;
  }

  .foot {
    width: 95%;
  }
}
</style>
