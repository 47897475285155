<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "product4",
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      pid: 1,
      productList: [
        {
          // pid=1

        }
      ]
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
  },
  methods: {
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container">
      <div class="product clearfix">
        <div class="product_introduction clearfix">
          <div class="img">
            <img src="@/assets/images/pd-4-m.png" alt="乐肤洁控油清爽洁面乳">
          </div>
          <div class="content">
            <div class="title">豆乎植合维肤霜&nbsp;</div>
            <div class="title_e">ZHIHE WEIFU CREAM</div>
            <!--%if(!pr.getTag().equals("")){%> <div class="txt_0"><span>%=pr.getTag()%></span></div>%}%>-->
            <div class="content_txt">
              <div class="introduce">
                <div class="txt_0">减轻痘痘症状，抑菌防扩散</div>
                <div class="txt_1">
                  <dd>通过天然保湿因子的作用软化角质，配以温和脱落角质的成分，渗透软化坚硬的表皮组织，打开毛孔帮助疏通堵塞的毛囊导管，为抗炎因子渗透入病灶开辟快速通道。
                    <dd>多种天然而强效的抗炎成分抑制炎性介质的分泌，促进具有拮抗炎性介质作用的物质生成。
                      <dd>通过天然抑菌成分可从根本抑制细菌的增殖分化，可有效抑制痤疮丙酸杆菌、表皮葡萄球菌等增殖，无副作用同时达到抑菌功能。
                        <dd>促进伤口愈合，预防疤痕产生，及时修复受损肌肤，促进细胞生长。
                          <dd>多重保湿，预防毛囊导管堵塞，改善干燥，恢复毛囊导管的正常运输功能。
                            <dd>调节油脂，预防脂质改变加重炎症，从源头减轻毛囊导管运输压力，保持通畅。</dd>
                </div>
                <div class="txt_2 clearfix"><b>使用方法：&nbsp;</b><span>取适量置于指腹，点涂于痘痘部位，按摩至吸收，一天使用5-6次，随时护理。</span>
                </div>
                <div class="txt_3 clearfix">
                  <div style="HEIGHT: 50px; CLEAR: both"><span>￥ 139</span><span class="s">/30g
										</span><a style="cursor: pointer" href="https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.8.5aa4410cxaGrJN&id=828824544734&skuId=5725696906455"
                              target="_blank">立即购买</a></div>
                </div>
              </div>
            </div>
          </div>
          <h3
              style=" width:138px; text-align:center; float:right; font-size: 14px; padding:15px 0; color: #21bdbd; font-weight: bold;">
            搭配使用更有效</h3>
          <ul class="related_products">
            <li>
              <a><img src="@/assets/images/pd-1-a.png"
                      alt="乐肤洁抗痘护理爽肤液"></a>
              <br>
              豆乎氨基酸净澈洁面乳
              <span class="li_a"><a @click="pathToProduct('none', 1)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-2-a.png"
                      alt="乐肤洁清爽控油乳液"></a>
              <br>
              豆乎萃活康肤水
              <span class="li_a"><a @click="pathToProduct('none', 2)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-3-a.png"
                      alt="乐肤洁隐形净痘贴"></a>
              <br>
              豆乎植合舒安乳
              <span class="li_a"><a @click="pathToProduct('none', 3)" style="cursor: pointer">查看</a></span>
            </li>
          </ul>
        </div>
        <div class="container-image-box">
          <img class="container-image" src="@/assets/images/pd-4-d.jpg" alt>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";

.container-image-box {
  width: 100%;
  display: flex;
  justify-content: center;

  .container-image {
    width: 650px;
    margin: 50px auto;
  }
}
</style>
