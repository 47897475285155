<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "Product",
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      pid: 1,
      productList: [
        {
          // pid=1

        }
      ]
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
  },
  methods: {

  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container">
      <div class="product clearfix">
        <div class="product_introduction clearfix">
          <div class="seriesbtn_f" v-if="false">
            <div class="seriesbtn">
              查<br>看<br>系<br>列<br>产<br>品
            </div>
          </div>
          <div class="img">
            <img src="@/assets/static/picture/kongyouqingshuangjiemian50.png" alt="乐肤洁控油清爽洁面乳">
          </div>
          <div class="content">
            <div class="title">乐肤洁控油清爽洁面乳&nbsp;</div>
            <div class="title_e">Creamy Wash100g/50g</div>
            <!--%if(!pr.getTag().equals("")){%> <div class="txt_0"><span>%=pr.getTag()%></span></div>%}%>-->
            <div class="content_txt">
              <div class="introduce">
                <div class="txt_0">氨基酸配方，新升级双重积雪草修护，温和祛痘不刺激</div>
                <div class="txt_1">
                  <dd>抗痘控油：含水杨酸及鳄梨果精华控油配方，有效去除多余油脂，深层调理肌肤
                    <dd>修复痘肌：严选≥95%的马达加斯加野生积雪草提取精华，研究显示有助于修护痘肌 含维他命C+E 修护抗氧化组合，能舒缓泛红痘肌及青春痘引起的不适感
                      <dd>温和亲肤：氨基酸无皂配方，贴近肌肤PH值。洁净不紧绷，适合脆弱痘肌 </dd>
                </div>
                <div class="txt_2 clearfix"><b>使用方法：&nbsp;</b><span>适量于手中，用水揉搓至起泡，按摩脸部，以清水充分清洗。</span>
                </div>
                <div class="txt_3 clearfix">
                  <div style="HEIGHT: 50px; CLEAR: both"><span>￥ 29.9</span><span class="s">/100g
										</span><a style="cursor: pointer"
                      target="_blank">立即购买</a></div>
                </div>
              </div>
            </div>
          </div>
          <h3
              style=" width:138px; text-align:center; float:right; font-size: 14px; padding:15px 0; color: #21bdbd; font-weight: bold;">
            搭配使用更有效</h3>
          <ul class="related_products">
            <li>
              <a href="product-2247C227_6.html"><img src="@/assets/static/picture/kandouhulishuangfuye_s.png"
                                                     alt="乐肤洁抗痘护理爽肤液"></a>
              <br>
              乐肤洁抗痘护理爽肤液
              <span class="li_a"><a href="product-2247C227_6.html">爽肤</a></span>
            </li>
            <li>
              <a href="product-2247C228_9.html"><img src="@/assets/static/picture/qingshuangkongyouruye_s.png"
                                                     alt="乐肤洁清爽控油乳液"></a>
              <br>
              乐肤洁清爽控油乳液
              <span class="li_a"><a href="product-2247C228_9.html">润肤</a></span>
            </li>
            <li>
              <a href="product-2257C229_52.html"><img src="@/assets/static/picture/yinxinjindoutie_s.png"
                                                      alt="乐肤洁隐形净痘贴"></a>
              <br>
              乐肤洁隐形净痘贴
              <span class="li_a"><a href="product-2257C229_52.html">痘痘特殊护理</a></span>
            </li>
          </ul>
        </div>
        <div style="height:40px; display:none;"> <!-- 20190517去掉成分介绍  -->
          <div class="tablist clearfix">
            <ul class="clearfix">
              <li>
                <span>明星成分</span>
                <div class="icon"><img src="@/assets/static/picture/product_tablist_0.jpg"></div>
              </li>
              <li>
                <span>抗痘秘诀</span>
                <div class="icon"><img src="@/assets/static/picture/product_tablist_1.jpg"></div>
              </li>
              <li>
                <span>产品评测</span>
                <div class="icon"><img src="@/assets/static/picture/product_tablist_2.jpg"></div>
              </li>
              <li>
                <span>达人推荐</span>
                <div class="icon"><img src="@/assets/static/picture/product_tablist_3.jpg"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="tablist_nav" style="display:none;"> <!-- 20190517去掉成分介绍  -->
          <ul>
            <li class="on">明星成分</li>
            <li>抗痘秘诀</li>
            <li>产品评测</li>
            <li>达人推荐</li>
            <li class="back">返回<br>顶部</li>
          </ul>
        </div>
        <div class="tab_content" style="display:none;"> <!-- 20190517去掉成分介绍  -->
          <div class="content_c content_0">
            <div class="title"
                 style="height:116px; background-color:#008955; text-align:center; padding:0px; margin-top:-36px;">
              <img src="@/assets/static/picture/product_content_title_meta0.jpg">
            </div>
            <div class="content_txt">
              <div><img border="0" src="@/assets/static/picture/sichong.jpg"></div>
            </div>
          </div>
          <div class="content_c content_1">
            <div class="title"
                 style="height:116px; background-color:#008955; text-align:center; padding:0px;">
              <img src="@/assets/static/picture/product_content_title_meta1.jpg">
            </div>
            <div class="content_txt">
              <div><img border="0" src="@/assets/static/picture/zhanxin.jpg"></div>
            </div>
          </div>
          <div class="content_c content_2">
            <div class="title"
                 style="height:116px; background-color:#008955; text-align:center; padding:0px;">
              <img src="@/assets/static/picture/product_content_title_meta2.jpg">
            </div>
            <div class="content_txt">
              <div><img border="0" src="@/assets/static/picture/kongyouqingshuagnjiemianru_pingce.jpg"></div>
            </div>
          </div>
          <div class="content_c content_3">
            <div class="title"
                 style="height:116px; background-color:#008955; text-align:center; padding:0px;">
              <img src="@/assets/static/picture/product_content_title_meta3.jpg">
            </div>
            <div class="content_txt">
              <table border="0" cellspacing="0" cellpadding="0" width="1003" height="200">
                <tbody>
                <tr>
                  <td colspan="5"><img src="@/assets/static/picture/qingshuan_daren_01.jpg" width="1003"
                                       height="573"></td>
                </tr>
                <tr>
                  <td><img src="@/assets/static/picture/qingshuan_daren_02.jpg"></td>
                  <td><a href="https://detail.tmall.com/item.htm?spm=a1z10.4-b-s.w5003-14917736672.2.eygjut&amp;id=5040303180&amp;rn=7651168963fcd04364d7b0d7bf1e1bc3&amp;abbucket=16&amp;scene=taobao_shop&amp;skuId=21163641412"
                         target="_blank"><img src="@/assets/static/picture/qingshuan_daren_03.jpg"></a>
                  </td>
                  <td><a href="https://detail.tmall.com/item.htm?spm=a1z10.4-b-s.w5003-14917736672.13.eygjut&amp;id=5038565574&amp;rn=b87aeefa620a3400c930b41c4922d78e&amp;abbucket=7&amp;skuId=28483488924&amp;scene=taobao_shop"
                         target="_blank"><img src="@/assets/static/picture/qingshuan_daren_04.jpg"></a>
                  </td>
                  <td><a href="https://detail.tmall.com/item.htm?spm=a1z10.4-b-s.w5003-14917736672.1.eygjut&amp;id=45511272164&amp;rn=7651168963fcd04364d7b0d7bf1e1bc3&amp;abbucket=16&amp;scene=taobao_shop"
                         target="_blank"><img src="@/assets/static/picture/qingshuan_daren_05.jpg"></a>
                  </td>
                  <td><a href="https://detail.tmall.com/item.htm?spm=a1z10.4-b-s.w5003-14917736672.3.eygjut&amp;id=5040210492&amp;rn=7651168963fcd04364d7b0d7bf1e1bc3&amp;abbucket=16&amp;scene=taobao_shop&amp;skuId=23454791263"
                         target="_blank"><img src="@/assets/static/picture/qingshuan_daren_06.jpg"></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";
</style>
