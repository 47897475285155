<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "compont",
  components: {HomeFooter, HomeHeader}
}
</script>

<template>
  <div>
    <home-header></home-header>
    <div class="header-title">
      产品专利成分
    </div>
    <div class="main-container">
      <div class="main-page">
        <div class="page-title">
          一种具有祛痘痕且抑制粉刺<br>
          生成功能的组合物及其应用
          <div class="page-title-mini">
            专利号CN201510873881.2
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            组合物成分
          </div>
          <div class="page-item-text">
            A组分:包括植物甾醇(0.1~1份)、红没药醇(0.1~2份)和甘草次酸硬脂醇酯(0.1~1份)。<br>
            B组分:包括根皮素(0.1~1份)、乙酰壳糖胺(0.5~5份)、肝素钠(0.05~1份)积雪草苷(0.1~1份)和曲克芦丁(0.1~2份)。<br>
            A组分和B组分的比例为0.3~3:0.85~10。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            技术特点
          </div>
          <div class="page-item-text">
            所有原料均收录于中国《已批准使用化妆品原料目录》，确保了产品的安全性和合规性。该组合物能够有效消除红色痘印、减淡黑色痘印、修复痘疤，并抑制新粉刺的生成。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            技术实现步骤
          </div>
          <div class="page-item-text">
            痘痕的形成是由于皮肤损伤深及真皮或大面积表皮缺损,导致表皮不能再生,由真皮纤维细胞、胶原及增生的血管所取代。痘印则是由于青春痘发作时引起血管扩张，青春痘消退后血管不会马.上缩回去，形成红斑,同时皮肤细胞的发炎反应造成色素沉淀，形成黑印。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            应用实例
          </div>
          <div class="page-item-text">
            霜剂:每100重量份霜剂包括油溶相组分和水溶相组分，具体成分包括A组分、鲸蜡硬脂基葡糖苷、鲸蜡醇磷酸酯钾、茶叶油、鲸蜡硬脂醇、维生素E醋酸酯、聚二甲基硅氧烷等。<br>
            乳液:每100重量份乳液同样包括油溶相组分和水溶相组分，具体成分包括A组分、鲸
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            实验结果
          </div>
          <div class="page-item-text">
            通过人体半脸试验评价，结果显示该组合物在消除红色痘印、减淡黑色痘印、修复痘疤和抑制新粉刺生成方面效果显著。<br>
            该专利通过科学的成分配比和严格的实验验证，提供了-种有效的祛痘痕和抑制粉刺生成的解决方案。
          </div>
        </div>
      </div>
      <div class="main-page">
        <div class="page-title">
          一种具有祛痘且抑制疫痕生<br>
          成功能的组合物及其应用
          <div class="page-title-mini">
            专利号CN201510876161.1
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            组合物成分及配比
          </div>
          <div class="page-item-text">
            A组分:包括植物甾醇、穗花牡荆油、红没药醇和甘草次酸硬脂醇酯。<br>
            B组分:包括根皮素、甜菜碱水杨酸盐、乙酰壳糖胺、肝素钠和积雪草苷;<br>
            A组分和B组分的重量比例在不同实施例中有所不同，例如2.5:8、2.5:6.5、3:6.5等
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            技术特点及应用实例
          </div>
          <div class="page-item-text">
            该组合物设计用于祛痘并抑制痘痕的生成;<br>
            提供了多种不同制剂形式的实施例，包括霜剂、乳液、精华液、凝胶和护肤水。<br>
            每种制剂都详细列出了油溶相组分和水溶相组分的具体成分和比例。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            制备方法及实验验证
          </div>
          <div class="page-item-text">
            描述了霜剂、乳液、精华液等的制备方法，包括加热、混合、均质、降温和装瓶等步骤;<br>
            进行了人体半脸试验，通过对比使用前后的痘痕外观状态，评价色度、色印面积、创面平整度和新生粉刺数量，证实了组合物的有效性。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            专利保护点及技术人员
          </div>
          <div class="page-item-text">
            专利保护的是一种具有祛痘且抑制痘痕生成功能的组合物，以及其在皮肤护理产品上的应用;<br>
            专利技术研发人员包括殷传江和杨敏。
          </div>
        </div>
        <div class="page-item">
          <div class="page-item-title">
            实验结果
          </div>
          <div class="page-item-text">
            通过.上述关键内容的提取，可以清晰地了解该专利的核心技术、应用范围以及其在实际产品中的应用形式。
          </div>
        </div>
      </div>
    </div>
    <div class="green-bg"></div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
.header-title {
  margin: 160px auto 0;
  width: fit-content;
  font-size: 40px;
  letter-spacing: 5px;
}

.main-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  gap: 100px;
  position: relative;
  z-index: 20;

  .main-page {
    border: 1px solid #058338;
    padding: 30px;
    width: 500px;
    background-color: #fff;

    .page-title {
      color: #058338;
      font-size: 26px;
      padding-bottom: 20px;
      border-bottom: 2px solid #058338;

      .page-title-mini {
        font-size: 14px;
        margin-top: 20px;
      }
    }
    .page-item {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #058338;

      .page-item-title {
        color: #058338;
        font-size: 20px;
      }
      .page-item-text {
        line-height: 1.5;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}

.green-bg {
  width: 100%;
  height: 400px;
  background-color: #058338;
  margin-top: -300px;
}
</style>
