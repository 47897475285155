<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "product3",
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      pid: 1,
      productList: [
        {
          // pid=1

        }
      ]
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
  },
  methods: {
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container">
      <div class="product clearfix">
        <div class="product_introduction clearfix">
          <div class="img">
            <img src="@/assets/images/pd-3-m.png" alt="乐肤洁控油清爽洁面乳">
          </div>
          <div class="content">
            <div class="title">豆乎植合舒安乳&nbsp;</div>
            <div class="title_e">ZHHE SHUAN MILK</div>
            <!--%if(!pr.getTag().equals("")){%> <div class="txt_0"><span>%=pr.getTag()%></span></div>%}%>-->
            <div class="content_txt">
              <div class="introduce">
                <div class="txt_0">遵循皮肤自愈规律的修复体系</div>
                <div class="txt_1">
                  <dd>从源头抵抗炎症，加强角质层修复，提升保水能力，补充细胞更新所需的营养。
                    <dd>选择天然草本提取物，温和低敏，从舒缓到抗炎，全面提高皮肤抵御力，重建健康的皮肤环境。
                      <dd>大量补充皮肤更新所需的营养素，有效促进老化细胞脱落，加快恢复和强化屏障功能的速度。
                        <dd>多重保湿因子降低经皮失水率，帮助角质层细胞吸水，维持水合功能。
                          <dd>抗氧化之王缓解自由基伤害，降低损伤，预防其他皮肤问题的产生。
                            <dd>抑制酪氨酸酶淡化色素，预防痘印，使肌肤恢复健康亮白。</dd>
                </div>
                <div class="txt_2 clearfix"><b>使用方法：&nbsp;</b><span>将乳液点在两颊、额头鼻头、下巴处，用指腹均匀涂抹全脸，轻揉长痘部位，增强修护效果。</span>
                </div>
                <div class="txt_3 clearfix">
                  <div style="HEIGHT: 50px; CLEAR: both"><span>￥ 89</span><span class="s">/60g
										</span><a style="cursor: pointer" href="https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.7.5aa4410cxaGrJN&id=828675209013&skuId=5557118840790"
                              target="_blank">立即购买</a></div>
                </div>
              </div>
            </div>
          </div>
          <h3
              style=" width:138px; text-align:center; float:right; font-size: 14px; padding:15px 0; color: #21bdbd; font-weight: bold;">
            搭配使用更有效</h3>
          <ul class="related_products">
            <li>
              <a><img src="@/assets/images/pd-1-a.png"
                      alt="乐肤洁抗痘护理爽肤液"></a>
              <br>
              豆乎氨基酸净澈洁面乳
              <span class="li_a"><a @click="pathToProduct('none', 1)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-2-a.png"
                      alt="乐肤洁清爽控油乳液"></a>
              <br>
              豆乎萃活康肤水
              <span class="li_a"><a @click="pathToProduct('none', 2)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-4-a.png"
                      alt="乐肤洁隐形净痘贴"></a>
              <br>
              豆乎植合维肤霜
              <span class="li_a"><a @click="pathToProduct('none', 4)" style="cursor: pointer">查看</a></span>
            </li>
          </ul>
        </div>
        <div class="container-image-box">
          <img class="container-image" src="@/assets/images/pd-3-d.jpg" alt>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";

.container-image-box {
  width: 100%;
  display: flex;
  justify-content: center;

  .container-image {
    width: 650px;
    margin: 50px auto;
  }
}
</style>
