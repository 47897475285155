var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('home-header'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"product clearfix"},[_c('div',{staticClass:"product_introduction clearfix"},[_vm._m(0),_vm._m(1),_c('h3',{staticStyle:{"width":"138px","text-align":"center","float":"right","font-size":"14px","padding":"15px 0","color":"#21bdbd","font-weight":"bold"}},[_vm._v(" 搭配使用更有效")]),_c('ul',{staticClass:"related_products"},[_c('li',[_vm._m(2),_c('br'),_vm._v(" 豆乎氨基酸净澈洁面乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 1)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(3),_c('br'),_vm._v(" 豆乎萃活康肤水 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 2)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(4),_c('br'),_vm._v(" 豆乎植合维肤霜 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 4)}}},[_vm._v("查看")])])])])]),_vm._m(5)])]),_c('home-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/pd-3-m.png"),"alt":"乐肤洁控油清爽洁面乳"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("豆乎植合舒安乳 ")]),_c('div',{staticClass:"title_e"},[_vm._v("ZHHE SHUAN MILK")]),_c('div',{staticClass:"content_txt"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"txt_0"},[_vm._v("遵循皮肤自愈规律的修复体系")]),_c('div',{staticClass:"txt_1"},[_c('dd',[_vm._v("从源头抵抗炎症，加强角质层修复，提升保水能力，补充细胞更新所需的营养。 ")]),_c('dd',[_vm._v("选择天然草本提取物，温和低敏，从舒缓到抗炎，全面提高皮肤抵御力，重建健康的皮肤环境。 ")]),_c('dd',[_vm._v("大量补充皮肤更新所需的营养素，有效促进老化细胞脱落，加快恢复和强化屏障功能的速度。 ")]),_c('dd',[_vm._v("多重保湿因子降低经皮失水率，帮助角质层细胞吸水，维持水合功能。 ")]),_c('dd',[_vm._v("抗氧化之王缓解自由基伤害，降低损伤，预防其他皮肤问题的产生。 ")]),_c('dd',[_vm._v("抑制酪氨酸酶淡化色素，预防痘印，使肌肤恢复健康亮白。")])]),_c('div',{staticClass:"txt_2 clearfix"},[_c('b',[_vm._v("使用方法： ")]),_c('span',[_vm._v("将乳液点在两颊、额头鼻头、下巴处，用指腹均匀涂抹全脸，轻揉长痘部位，增强修护效果。")])]),_c('div',{staticClass:"txt_3 clearfix"},[_c('div',{staticStyle:{"HEIGHT":"50px","CLEAR":"both"}},[_c('span',[_vm._v("￥ 89")]),_c('span',{staticClass:"s"},[_vm._v("/60g ")]),_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.7.5aa4410cxaGrJN&id=828675209013&skuId=5557118840790","target":"_blank"}},[_vm._v("立即购买")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-1-a.png"),"alt":"乐肤洁抗痘护理爽肤液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-2-a.png"),"alt":"乐肤洁清爽控油乳液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-4-a.png"),"alt":"乐肤洁隐形净痘贴"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-image-box"},[_c('img',{staticClass:"container-image",attrs:{"src":require("@/assets/images/pd-3-d.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }