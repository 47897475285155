var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('home-header'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"product clearfix"},[_c('div',{staticClass:"product_introduction clearfix"},[_vm._m(0),_vm._m(1),_c('h3',{staticStyle:{"width":"138px","text-align":"center","float":"right","font-size":"14px","padding":"15px 0","color":"#21bdbd","font-weight":"bold"}},[_vm._v(" 搭配使用更有效")]),_c('ul',{staticClass:"related_products"},[_c('li',[_vm._m(2),_c('br'),_vm._v(" 豆乎萃活康肤水 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 2)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(3),_c('br'),_vm._v(" 豆乎植合舒安乳 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 3)}}},[_vm._v("查看")])])]),_c('li',[_vm._m(4),_c('br'),_vm._v(" 豆乎植合维肤霜 "),_c('span',{staticClass:"li_a"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.pathToProduct('none', 4)}}},[_vm._v("查看")])])])])]),_vm._m(5)])]),_c('home-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/pd-1-m.png"),"alt":"乐肤洁控油清爽洁面乳"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("豆乎氨基酸净澈洁面乳 ")]),_c('div',{staticClass:"title_e"},[_vm._v("AMINO ACID CLEANSER")]),_c('div',{staticClass:"content_txt"},[_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"txt_0"},[_vm._v("干净舒适的氨基酸洁面乳")]),_c('div',{staticClass:"txt_1"},[_c('dd',[_vm._v("氨基酸表活结构天生与皮肤接近，带来优秀的清洁力同时，温和无刺激。 ")]),_c('dd',[_vm._v("挑选国际品牌热推的两大氨基酸表活，不添加任何皂基成分，易冲水，洗后肌肤清透净白。 ")]),_c('dd',[_vm._v("保护天然保湿因子，不过度脱脂，控制水分蒸发，有效改善清洁后紧绷感。 ")])]),_c('div',{staticClass:"txt_2 clearfix"},[_c('b',[_vm._v("使用方法： ")]),_c('span',[_vm._v("用清水将全脸湿润，取花生米大小(约1g) 于掌心加适量水搓揉出泡沫，用泡沫轻柔按摩全脸，可重点揉搓前额、鼻子和下巴位置，约1分钟后使用清水将脸洗净即可。")])]),_c('div',{staticClass:"txt_3 clearfix"},[_c('div',{staticStyle:{"HEIGHT":"50px","CLEAR":"both"}},[_c('span',[_vm._v("￥ 59.9")]),_c('span',{staticClass:"s"},[_vm._v("/98g ")]),_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"https://detail.tmall.com/item.htm?spm=a1z10.1-b.w4004-25532185107.3.5aa4410cxaGrJN&id=826772451189","target":"_blank"}},[_vm._v("立即购买")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-2-a.png"),"alt":"乐肤洁抗痘护理爽肤液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-3-a.png"),"alt":"乐肤洁清爽控油乳液"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/images/pd-4-a.png"),"alt":"乐肤洁隐形净痘贴"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-image-box"},[_c('img',{staticClass:"container-image",attrs:{"src":require("@/assets/images/pd-1-d.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }