<script>
export default {
  name: "homeHeader",
  data() {
    return {
      subHover: -1
    }
  },
  methods: {
    /** 跳转 */
    pathTo(url) {
      this.$router.push({
        path: url
      })
    },
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  },
}
</script>

<template>
  <div class="page">
    <div class="header" :class="{'header-height':subHover === 1 || subHover === 2}" @mouseleave="subHover = -1">
      <div @click="pathTo('/')" class="content">
        <a style="cursor: pointer"><img class="logo" src="@/assets/images/logo_douhu.png" alt=""></a>
        <ul class="clearfix fristbtn">
          <li style="width:45px">
            <a @mouseenter="subHover = 0" style="cursor: pointer" @click="pathTo('/')">
              <span class="frist_txt" :class="{'header-opacity':subHover === 0}">首页</span>
<!--              <div class="img" :class="{'header-display':subHover === 0}"><img src="@/assets/static/picture/acnes_cat_index.jpg" alt=""></div>-->
            </a>
          </li>
          <li class="">
            <a @mouseenter="subHover = 1" style="cursor: pointer">
              <span class="frist_txt" :class="{'header-opacity':subHover === 1}">抗痘护理系列</span>
<!--              <div class="img" :class="{'header-display':subHover === 1}"><img src="@/assets/static/picture/index_header_txt_1.jpg" alt="抗痘护理系列"></div>-->
            </a>
            <div class="subnav su_0" style="padding-top: 45px;" :class="{'header-block':subHover === 1}">
              <div class="closebtn">
                <img src="@/assets/static/picture/subtn_close_btn.jpg">
              </div>
              <dl>
                <dd>
                  <div class="title title-none">
                    清洁
                  </div>
                  <a @click="pathToProduct('/product', 1)">
                    <div class="title-none">
                      <div>
                        <img src="@/assets/images/pd-1-m.png" alt="乐肤洁控油清爽洁面乳">
                      </div>
                      <div class="sub_title">
                        豆乎氨基酸净澈洁面乳<br>98g
                        <span>
												温和无刺激<br>敏感肌适用
											</span>
                      </div>
                    </div>
                  </a>
                </dd>
                <dd>
                  <div class="title title-none">
                    控油
                  </div>
                  <a @click="pathToProduct('/product', 2)">
                    <div class="title-none">
                      <div>
                        <img src="@/assets/images/pd-2-m.png" alt="乐肤洁控油清爽洁面乳">
                      </div>
                      <div class="sub_title">
                        豆乎萃活康肤水<br>80ml
                        <span>
                        毛孔调理，打好肌底<br>去油+控油，一步到位
											</span>
                      </div>
                    </div>
                  </a>
                </dd>
                <dd>
                  <div class="title title-none">
                    修护
                  </div>
                  <a @click="pathToProduct('/product', 3)">
                    <div class="title-none">
                      <div>
                        <img src="@/assets/images/pd-3-m.png" alt="乐肤洁控油清爽洁面乳">
                      </div>
                      <div class="sub_title">
                        豆乎植合舒安乳<br>60g
                        <span>
												缓和肌肤应激反应<br>辅助吸收，敏感肌适用
											</span>
                      </div>
                    </div>
                  </a>
                </dd>
                <dd class="last">
                  <div class="title title-none">
                    祛痘
                  </div>
                  <a @click="pathToProduct('/product', 4)">
                    <div class="title-none">
                      <div>
                        <img src="@/assets/images/pd-4-m.png" alt="乐肤洁清爽洁面泡沫">
                      </div>
                      <div class="sub_title">
                        豆乎植合维肤霜<br>30g
                        <span>
												抑菌消炎，软化角质<br>促进肌肤再生，不留痘坑
											</span>
                      </div>
                    </div>
                  </a>
                </dd>
              </dl>
            </div>
          </li>
          <li class="">
            <!--  -->
            <a @mouseenter="subHover = 2" style="cursor: pointer">
              <span class="frist_txt" :class="{'header-opacity':subHover === 2}">特殊护理系列</span>
<!--              <div class="img" :class="{'header-display':subHover === 2}"><img src="@/assets/static/picture/index_header_txt_2.jpg" alt="特殊护理系列"></div>-->
            </a>
            <div class="subnav su_1" :class="{'header-block':subHover === 2}" style="width: 1100px">
              <div class="closebtn">
                <img src="@/assets/static/picture/subtn_close_btn.jpg">
              </div>
              <dl class="clearfix">
                <dd class="title title-left">
                  痘痘特殊护理
                </dd>
                <dd>
                  <a >
                    <div>
                      <img src="@/assets/static/picture/acnes_58.png" alt="乐肤洁隐形净痘贴（日夜双效）">
                    </div>
                    <div class="sub_title">
                      豆乎隐形净痘贴（日夜双效）<br>52片
                      <span>
												吸走痘痘<br>一贴遮瑕
											</span>
                    </div>
                  </a>
                </dd>
                <dd>
                  <a >
                    <div>
                      <img src="@/assets/static/picture/acnes_52.png" alt="乐肤洁隐形净痘贴">
                    </div>
                    <div class="sub_title">
                      豆乎隐形净痘贴<br>26片
                      <span>
												吸走痘痘<br>一贴遮瑕
											</span>
                    </div>
                  </a>
                </dd>
              </dl>
            </div>
          </li>
          <li class="">
            <a @mouseenter="subHover = 3" style="cursor: pointer" @click="pathTo('/compont')">
              <span class="frist_txt" :class="{'header-opacity':subHover === 3}">专利祛痘成分</span>
<!--              <div class="img" :class="{'header-display':subHover === 3}" style="text-align:left;left: -10px;"><img-->
<!--                  src="@/assets/static/picture/index_header_txt_3.jpg" alt="专利祛痘成分"></div>-->
            </a>
          </li>
          <li class="" style="display: none; ">
            <a >
              <span class="frist_txt">达人亲测</span>
              <div class="img"><img src="@/assets/static/picture/index_header_txt_4.jpg" alt="达人亲测"></div>
            </a>
          </li>
          <li class=""
              style="background:url(../assets/static/image/index_header_icon4.jpg) no-repeat center top; padding-left:43px; padding-right:30px;display: none; ">
            <a >
              <span class="frist_txt">分享有奖</span>
              <div class="img"><img src="@/assets/static/picture/index_header_txt_5.jpg" alt="分享有奖"></div>
            </a>
          </li>
          <li class=""
              style="display: flex; align-items: center; width: fit-content">
            <img src="@/assets/static/image/index_header_icon6.png" style="width: 42px; height: 31px; margin-right: 20px">
            <a @mouseenter="subHover = 4" style="cursor: pointer" href="https://douhuhzp.tmall.com/" target="_blank">
              <span class="frist_txt" :class="{'header-opacity':subHover === 4}">立即拥有</span>
<!--              <div class="img" :class="{'header-display':subHover === 4}"><img src="@/assets/static/picture/index_header_txt_6.jpg" alt="立即拥有"></div>-->
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";
a {
  cursor: pointer !important;
}

.header-height {
  height: 330px !important;
}
.header-opacity {
  //opacity: 0 !important;
  font-weight: bold;
}
.header-display {
  top: 0 !important;
}
.header-block {
  display: block !important;
}
</style>
